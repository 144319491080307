<template>
  <div v-if="events.length > 0">
    <divider :white="dark" />
    <div
      class="flex-1 font-title italic text-title md:text-mdtitle lg:text-lgtitle xl:text-xltitle leading-title md:leading-mdtitle lg:leading-lgtitle xl:leading-xltitle lg:max-w-block m-padding md:m-mdpadding xl:m-xlpadding xxl:mx-xxlpadding"
    >
      Ellos lo han celebrado <br />
      <span
        class="cursor-pointer underlined"
        :class="{ 'underlined--white': dark }"
        >a su manera</span
      >
    </div>

    <div class="clearfix"></div>

    <div
      class="cards-grid m-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding justify-center items-center"
    >
      <router-link
        v-for="event in events"
        :key="event.nombreUrl"
        :style="{ backgroundImage: `url(${event.fotoMiniatura})` }"
        :to="{
          name: `${projectType}-details`,
          params: {
            project: event.nombreUrl,
            event: JSON.stringify(event),
          },
        }"
        class="event-card card-size relative"
        @mouseover="hover = event.nombreUrl"
        @mouseout="hover = null"
      >
        <div class="event-card--hover" v-show="hover === event.nombreUrl">
          <div class="flex justify-center items-center">
            <span class="text-h3 leading-h3">Ver proyecto</span>
            <img
              src="../assets/img/arrow-white.svg"
              alt="ver proyecto"
              class="ml-4"
            />
          </div>
        </div>

        <div class="event-card--mobile-hover">
          <span class="text-h3 leading-h3">Ver proyecto</span>
          <img
            src="../assets/img/arrow-white.svg"
            alt="ver proyecto"
            class="ml-4"
          />
        </div>
      </router-link>

      <div
        v-if="events.length < 3"
        class="event-card example-card relative text-center hidden! md:flex!"
        :class="{ 'example-card--dark': dark }"
      >
        <div>
          {{ masculino ? "Este" : "Esta" }} puede ser tu<br />
          <strong>{{ eventHashtag }} </strong>. ¿Hablamos?
        </div>
        <img
          :src="
            require(`../assets/img/${
              dark ? 'point-right--white.svg' : 'point-right.svg'
            }`)
          "
          alt="ver proyecto"
          class="mt-4"
        />
      </div>
      <div
        v-if="events.length < 2"
        class="event-card example-card relative text-center hidden! lg:flex!"
        :class="{ 'example-card--dark': dark }"
      >
        <div>
          {{ masculino ? "Este" : "Esta" }} puede ser tu<br />
          <strong>{{ eventHashtag }}</strong
          >. ¿Hablamos?
        </div>
        <img
          :src="
            require(`../assets/img/${
              dark ? 'point-right--white.svg' : 'point-right.svg'
            }`)
          "
          alt="ver proyecto"
          class="mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CMSService from "@/services/CMSService";
import Divider from "./Divider.vue";

export default {
  components: { Divider },
  name: "Projects",
  props: {
    projectType: {
      type: String,
      default: "boda",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: null,
      events: [],
    };
  },
  computed: {
    masculino() {
      return this.projectType === "evento";
    },
    eventHashtag() {
      switch (this.projectType) {
        case "evento":
          return "#BenditoEvento";
        case "fiesta":
          return "#BenditaFiesta";
        case "boda":
        default:
          return "#BenditaBoda";
      }
    },
  },
  async created() {
    this.events = await CMSService.events(this.projectType);
  },
};
</script>

<style lang="scss" scoped>
.event-card {
  background-size: cover;
  background-position: center;

  &--mobile-hover {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    );
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    padding: 24px;
    color: white;

    @media (min-width: 1279px) {
      display: none;
    }
  }

  &--hover {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("../assets/pictures/overlay-grid--hover.svg");
    background-size: contain;
    background-position: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.example-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-image: url("../assets/pictures/overlay-grid.svg");
  background-color: #de949166;
  height: 100%;
  width: 100%;

  &--dark {
    background-color: transparent;
    background-image: url("../assets/pictures/overlay-grid--dark.svg");
  }
}
</style>
