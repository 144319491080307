<template>
  <section class="bodas bg-pink pt-topmenu xl:pt-0">
    <div class="hidden xl:block">
      <divider />

      <!-- Header -->
      <div class="mx-xlpadding xxl:mx-xxlpadding flex justify-end mb-10">
        <span
          class="text-sm circled"
          style="width: 56px; height: 28px; margin-top: 12px"
          >Bodas</span
        >
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row md:justify-between md:items-end mb-xlpadding mx-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding mt-5 md:mt-10"
    >
      <div class="md:w-half">
        <h1
          class="flex-1 font-title italic text-bigtitle lg:text-lgtitle xl:text-xltitle leading-bigtitle lg:leading-lgtitle xl:leading-xltitle md:max-w-block"
        >
          Benditas <br />
          Bodas.
        </h1>
      </div>

      <div
        class="flex-1 md:pl-8 mt-5 md:mt-0 text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium md:w-half"
      >
        Queremos hacer de este día algo realmente vuestro. No pararemos hasta
        lograrlo.
      </div>
    </div>

    <!-- Cover image -->
    <img
      src="../assets/pictures/cover-bodas.jpg"
      :srcset="`
        ${require(`../assets/pictures/cover-bodas${
          $mq === 'xs' ? '-mobile' : ''
        }.jpg`)},
        ${require(`../assets/pictures/cover-bodas${
          $mq === 'xs' ? '-mobile' : ''
        }@2x.jpg`)} 2x,
        ${require(`../assets/pictures/cover-bodas${
          $mq === 'xs' ? '-mobile' : ''
        }@3x.jpg`)} 3x
        `"
      style="width: 100%"
    />

    <div
      class="flex flex-col md:flex-row md:justify-between m-padding md:m-mdpadding lg:m-lgpadding xl:m-xlpadding xxl:mx-xxlpadding my-xlpadding"
    >
      <div
        class="text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium md:w-half"
      >
        <div class="md:max-w-blockm">
          Así que vamos a empezar a crear arte, bodas, fiestas y jaranas en toda
          regla.
        </div>
      </div>
      <div class="lg:flex lg:flex-wrap md:w-half mt-padding md:mt-0 md:pl-8">
        <div class="flex-1 lg:pr-4">
          <p>
            Cada persona es única, por lo que un día tan especial como éste,
            debe reflejar el carácter y la personalidad de quienes lo celebran.
          </p>
        </div>
        <div class="flex-1 lg:pl-4 mt-4 lg:mt-0">
          <p>
            Para Bendita Jarana es muy importante personalizar cada celebración
            y llevarla a otra dimensión. El único objetivo es celebrar la vida
            juntos.
          </p>
        </div>
      </div>
    </div>

    <projects project-type="boda" />

    <!-- Contact -->
    <divider />
    <contact-section />

    <!-- Footer -->
    <JFooter />
    <divider />
  </section>
</template>

<script>
import Divider from "../components/Divider";
import ContactSection from "../components/ContactSection";
import JFooter from "../components/Footer";
import Projects from "../components/Projects.vue";

export default {
  name: "Bodas",
  components: {
    Divider,
    ContactSection,
    JFooter,
    Projects,
  },
};
</script>

<style lang="scss" scoped></style>
